import Container from 'react-bootstrap/Container';
import { Card, Row, Modal, Image, Form, Accordion, Button, useAccordionButton} from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import { pb } from '../pocketbase';
import { useEffect, useState } from 'react';

function Home() {
    const [data, setData] = useState();
    const [showImgModal, setShowImgModal] = useState(false);
    const [modalImage, setModalImage] = useState();

    const [selectMaker, setSelectMaker] = useState([]);
    const [selectMakerOptions, setSelectMakerOptions] = useState([]);
    const [selectSize, setSelectSize] = useState([]);
    const [selectSizeOptions, setSelectSizeOptions] = useState([]);
    const [selectModel, setSelectModel] = useState([]);
    const [selectModelOptions, setSelectModelOptions] = useState([]);

    useEffect(() => {
        const record = pb.collection('posts').getList(1, 50, {
            expand: 'maker,model,size',
        });
        record.then(function (response) {
            setData(response.items);
            console.log(response);
        }, function (error) {
            console.log(error)

        })
        const makersProps = pb.collection('makers').getList(1, 50, {});
        const modelProps = pb.collection('model').getList(1, 50, {});
        const sizeProps = pb.collection('size').getList(1, 50, {});

        makersProps.then(function (response) { setSelectMakerOptions(response); }, function (error) { console.log(error); });
        modelProps.then(function (response) { setSelectModelOptions(response); }, function (error) { console.log(error); });
        sizeProps.then(function (response) { setSelectSizeOptions(response); }, function (error) { console.log(error); });
    }, [])

    const handleClose = () => setShowImgModal(false);
    const handleShow = () => {
        setShowImgModal(true);
    }

    const renderItmes = data?.map((item) =>
        <Card className='cardItem' style={{ width: '18rem' }} onClick={() => { setModalImage("https://pb.27021993.xyz/api/files/posts/" + item.id + "/" + item.image); setShowImgModal(true); }}>
            <Card.Img variant="top" src={"https://pb.27021993.xyz/api/files/posts/" + item.id + "/" + item.image} />
            <Card.Body>
                <Card.Title>{item.title}</Card.Title>
                <p>Модел:{item.expand.model.model}</p>
                <p>Производител:{item.expand.maker.maker}</p>
                <p>Размер:{item.expand.size.size}</p>
            </Card.Body>
        </Card>
    )
    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey, () =>
          console.log('totally custom!'),
        );
      
        return (
          <button
            type="button"
            className='filterBtn'
            onClick={decoratedOnClick}
          >
            {children}
          </button>
        );
      }
    return (<>
        <div className='heroDiv'>
            <h1 className='text-center'>DIECAST MK</h1>
            <h3 className='text-center'>Колекциите на македонски Diceast колекционери на едно место!</h3>
        </div>
        <Container className='carsList'>
            <Accordion defaultActiveKey="0">
            <CustomToggle eventKey="0">ФИЛТЕР</CustomToggle>
            <Accordion.Collapse eventKey="0">
                <Row className='hpFilter'>
                    <Form.Group controlId="formFile1" className="mb-3">
                        <Form.Label>Марка</Form.Label>
                        <Typeahead
                            id="listSelect"
                            labelKey="model"
                            onChange={setSelectModel}
                            options={selectModelOptions.items}
                            // placeholder="Choose a state..."
                            selected={selectModel}
                        />
                    </Form.Group>
                    <Form.Group controlId="formFile2" className="mb-3">
                        <Form.Label>Производител</Form.Label>
                        <Typeahead
                            id="listSelect"
                            labelKey="maker"
                            onChange={setSelectMaker}
                            options={selectMakerOptions.items}
                            // placeholder="Choose a state..."
                            selected={selectMaker}
                        />
                    </Form.Group>
                    <Form.Group controlId="formFile3" className="mb-3">
                        <Form.Label>Размер</Form.Label>
                        <Typeahead
                            id="listSelect"
                            labelKey="size"
                            onChange={setSelectSize}
                            options={selectSizeOptions.items}
                            // placeholder="Choose a state..."
                            selected={selectSize}
                        />
                    </Form.Group>
                </Row>
            </Accordion.Collapse>
            </Accordion>
                                        

            {/* <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>ФИЛТЕР</Accordion.Header>
                    <Accordion.Body>
                        <Row className='hpFilter'>
                            <Form.Group controlId="formFile1" className="mb-3">
                                <Form.Label>Марка</Form.Label>
                                <Typeahead
                                    id="listSelect"
                                    labelKey="model"
                                    onChange={setSelectModel}
                                    options={selectModelOptions.items}
                                    // placeholder="Choose a state..."
                                    selected={selectModel}
                                />
                            </Form.Group>
                            <Form.Group controlId="formFile2" className="mb-3">
                                <Form.Label>Производител</Form.Label>
                                <Typeahead
                                    id="listSelect"
                                    labelKey="maker"
                                    onChange={setSelectMaker}
                                    options={selectMakerOptions.items}
                                    // placeholder="Choose a state..."
                                    selected={selectMaker}
                                />
                            </Form.Group>
                            <Form.Group controlId="formFile3" className="mb-3">
                                <Form.Label>Размер</Form.Label>
                                <Typeahead
                                    id="listSelect"
                                    labelKey="size"
                                    onChange={setSelectSize}
                                    options={selectSizeOptions.items}
                                    // placeholder="Choose a state..."
                                    selected={selectSize}
                                />
                            </Form.Group>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion> */}

            <Row className='homePageRow'>
                {renderItmes}
                {renderItmes}
                {renderItmes}
                {renderItmes}
            </Row>
            Mod
        </Container>

        <Modal show={showImgModal} className="modal-lg" onHide={handleClose}>
            <Image src={modalImage} className="modalImage" />
        </Modal></>
    );
}

export default Home;
