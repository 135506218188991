import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import { useEffect, useState } from 'react';
import {pb} from '../pocketbase'
import '../App.css';

function Login() {
    const [usernameInput,setUsernameInput] = useState("");
    const [passwordInput, setPasswordInput] = useState("");
    useEffect(()=>{
        // console.log("before login",pb.authStore.model.id)
    },[])

    const handleLogin = async (e) => {
        e.preventDefault();
        const authData = await pb.collection('users').authWithPassword(usernameInput,passwordInput,passwordInput);
        console.log(pb.authStore.isValid);
        console.log(pb.authStore.token);
        console.log(pb.authStore.model.id);
        window.location = '/';
    }
    return (
        <Container className=''>
            <Form onSubmit={handleLogin} className='formStyle'>
                <h3 className='text-center mb-5'>ЛОГИРАЈ СЕ</h3>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Корисничко име:</Form.Label>
                    <Form.Control type="text" value={usernameInput} onChange={(e)=>{setUsernameInput(e.target.value)}}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Лозинка:</Form.Label>
                    <Form.Control type="password" value={passwordInput} onChange={(e)=>{setPasswordInput(e.target.value)}}/>
                </Form.Group>
                <Button variant="primary" className='primaryBtn' type="submit">Логирај се</Button>
                <p className='text-center mt-3'>Не си постоечки корисник? <a href='/register'>РЕГИСТРИРАЈ СЕ</a></p>
            </Form>
        </Container>
    );
}

export default Login;
