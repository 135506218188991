import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import { useEffect, useState } from 'react';
import {pb} from '../pocketbase'
import '../App.css';

function Register() {
    const [nameInput,setNameInput] = useState("");
    const [usernameInput,setUsernameInput] = useState("");
    const [emailInput,setEmailInput] = useState("");
    const [passwordInput, setPasswordInput] = useState("");
    const [confPasswordInput, seConftPasswordInput] = useState("");

    const [showAlert, setShowAlert] = useState(false);
    const [alertStatus, setAlertStatus] = useState('success');
    const [alertText, setAlertText] = useState('');

    useEffect(()=>{
        // console.log("before login",pb.authStore.model.id)
    },[])

    const handleLogin = async (e) => {
        e.preventDefault();
        var data ={
            "username": usernameInput,
            "email": emailInput,
            "emailVisibility": true,
            "password": passwordInput,
            "passwordConfirm": confPasswordInput,
            "name": nameInput 
        }
        if(passwordInput!=confPasswordInput){
            setShowAlert(true);
            setAlertStatus('danger');
            setAlertText('Внесените лозинки не се совпаѓаат!')
            window.scrollTo({top: 0, behavior: 'smooth'});
        }else if (nameInput === "" || usernameInput === "" || emailInput === "" || passwordInput === "" || confPasswordInput === ""){
            setShowAlert(true);
            setAlertStatus('danger');
            setAlertText('Ве молам пополнете ги сите полиња!')
            window.scrollTo({top: 0, behavior: 'smooth'});
        } else if(passwordInput.length < 8 || passwordInput.length > 72){
            setShowAlert(true);
            setAlertStatus('danger');
            setAlertText('Лозинката не ги задоволува критериумтие, внесете лозинка од 8 до 72 карактери!')
            window.scrollTo({top: 0, behavior: 'smooth'});
        }else{
            const record = pb.collection('users').create(data);
            record.then(function (response){
                setShowAlert(true);
                setAlertStatus('success');
                setAlertText('Успешна регистрација. Ќе бидете префлени на страната за логирање за 6 секунди!')
                setTimeout(() => {window.location='/login';}, 6000);
            }, function (error){
                console.log(error)
                setShowAlert(true);
                setAlertStatus('danger');
                setAlertText('Корисничкото име или емаил се веќе искористени')
                window.scrollTo({top: 0, behavior: 'smooth'});
            })
            
            
        }
                
    }
    return (
        <Container className=''>
            <Form onSubmit={handleLogin} className='formStyle'>
                <h3 className='text-center mb-5'>РЕГИСТИРАЈ СЕ</h3>
                {showAlert
                ?<Alert variant={alertStatus}>
                    <p className='text-center m-o'>{alertText}</p>
                </Alert>
                :<></>}
                <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Име и презиме:</Form.Label>
                    <Form.Control type="text" value={nameInput} onChange={(e)=>{setNameInput(e.target.value)}}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicUsername">
                    <Form.Label>Корисничко име:</Form.Label>
                    <Form.Control type="text" value={usernameInput} onChange={(e)=>{setUsernameInput(e.target.value)}}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Е-маил:</Form.Label>
                    <Form.Control type="email" value={emailInput} onChange={(e)=>{setEmailInput(e.target.value)}}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Лозинка:</Form.Label>
                    <Form.Control type="password" value={passwordInput} onChange={(e)=>{setPasswordInput(e.target.value)}}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicConfPassword">
                    <Form.Label>Потврди Лозинка:</Form.Label>
                    <Form.Control type="password" value={confPasswordInput} onChange={(e)=>{seConftPasswordInput(e.target.value)}}/>
                </Form.Group>
                <Button variant="primary" className='primaryBtn' type="submit">Регистирај се</Button>
                <p className='text-center mt-3'>Веќе си постоечки корисник? <a href='/login'>ЛОГИРАЈ СЕ</a></p>
            </Form>
        </Container>
    );
}

export default Register;
