import { Form, Button } from 'react-bootstrap';
// import BootstrapSelect from 'react-bootstrap-select-dropdown';
import { Typeahead } from 'react-bootstrap-typeahead';
import {pb} from '../../pocketbase'
import { useEffect, useState } from 'react';
import '../../App.css';


const AddImageFrom = (show) => {

    const [image, setImage] = useState();
    const [titlee, setTitle] = useState();
    const [showModal, setShowModal] = useState(show);
    const [selectMaker, setSelectMaker] = useState([]);
    const [selectMakerOptions, setSelectMakerOptions] = useState([]);
    const [selectSize, setSelectSize] = useState([]);
    const [selectSizeOptions, setSelectSizeOptions] = useState([]);
    const [selectModel, setSelectModel] = useState([]);
    const [selectModelOptions, setSelectModelOptions] = useState([]);
    const [multiSelections, setMultiSelections] = useState([]);   

    // const handleClose = () => setShowModal(false);
    // const handleShow = () => setShowModal(true);

    useEffect(() => {
        console.log(showModal.show)
    }, [showModal])

    useEffect(() => {
        const makersProps =  pb.collection('makers').getList(1, 50, {});
        const modelProps =  pb.collection('model').getList(1, 50, {});
        const sizeProps =  pb.collection('size').getList(1, 50, {});
        
        makersProps.then(function (response) {setSelectMakerOptions(response);}, function (error) {console.log(error); });
        modelProps.then(function (response) {setSelectModelOptions(response);}, function (error) {console.log(error); });
        sizeProps.then(function (response) {setSelectSizeOptions(response);}, function (error) {console.log(error); });
    }, [])

    // useEffect(() => {
    //     console.log(findCategoryId("makers",selectMaker))
    // }, [selectMaker])


    // function findCategoryId(category,name){
    //     if(category==="makers"){
    //         // const items = selectMakerOptions.items;
    //         // const result = items.filter(item => {return item.name === name});
    //         console.log(name)
    //     }
    // }

    const handleSubmit = async (e) => {
        e.preventDefault();

        var formData = new FormData();

        formData.append('maker', selectMaker[0].id);
        formData.append('image',image);
        formData.append('model', selectModel[0].id);
        formData.append('size', selectSize[0].id);
        formData.append('title', titlee);
        
        const record = pb.collection('posts').create(formData);
        record.then(function (response){
            console.log('post created');
            window.location = '/';
        }, function (error){
            console.log(error)
            
        })
    }
    return (
        <>
            <Form className='formStyle' onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Наслов</Form.Label>
                    <Form.Control type="text" onChange={(e) =>{setTitle(e.target.value)}}/>
                </Form.Group>
                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Слика</Form.Label>
                    <Form.Control type="file" onChange={(e) =>{setImage(e.target.files[0])}}/>
                </Form.Group>
                <Form.Group controlId="formFile1" className="mb-3">
                    <Form.Label>Марка</Form.Label>
                    <Typeahead
                        id="listSelect"
                        labelKey="model"
                        onChange={setSelectModel}
                        options={selectModelOptions.items}
                        // placeholder="Choose a state..."
                        selected={selectModel}
                    />
                </Form.Group>
                <Form.Group controlId="formFile2" className="mb-3">
                    <Form.Label>Производител</Form.Label>
                    <Typeahead
                        id="listSelect"
                        labelKey="maker"
                        onChange={setSelectMaker}
                        options={selectMakerOptions.items}
                        // placeholder="Choose a state..."
                        selected={selectMaker}
                    />
                </Form.Group>
                <Form.Group controlId="formFile3" className="mb-3">
                    <Form.Label>Размер</Form.Label>
                    <Typeahead
                        id="listSelect"
                        labelKey="size"
                        onChange={setSelectSize}
                        options={selectSizeOptions.items}
                        // placeholder="Choose a state..."
                        selected={selectSize}
                    />
                </Form.Group>
                <Button variant="primary" className='primaryBtn' type="submit">ПРИКАЧИ</Button>
            </Form>
        </>
    )
};

export default AddImageFrom;