import {Outlet} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { BsPersonCircle } from "react-icons/bs";
import LinkPrimaryBtn from './components/LinkPrimaryBtn'
import AddImageFrom from "./components/AddImageForm";
import '../App.css';
import { useEffect, useState } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import {pb} from '../pocketbase'

function Layout() {
    const [loading, setLoading] = useState(true);
    const [logedIn, setLogedIn] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
          }, 1000);
          if(pb.authStore.isValid){
            setLogedIn(true);
          }
    }, []);
    const handleLogOut = async (e) => {
        e.preventDefault();
        pb.authStore.clear();
        console.log("test click",pb.authStore.isValid)
        window.location = '/';
        setLogedIn(false);
    }
    return (
        <>
        {loading
            ? <div className="loader-container">
                {/* <div className="spinner"></div> */}
                <Player
                    autoplay
                    loop
                    src="https://lottie.host/62995dd2-777b-48f6-9523-9c78edf883c7/GbLK5KdZ3r.json"
                    style={{ height: '300px', width: '300px' }}
                    >
                    {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
                </Player>
            </div>
            :<><Navbar bg="dark"  variant="dark" expand="lg">
            <Container>
                <Navbar.Brand href="/"><img src="./car.png" width="30" height="30" class="d-inline-block align-top" alt="" /> &nbsp;Diecast MK</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/">Почетна</Nav.Link>
                        {logedIn
                        ?<><Nav.Link href="#link" onClick={handleShow}>Прикачи слика</Nav.Link></>
                        :<></>}
                        <Nav.Link href="/about">За нас</Nav.Link>
                        <Nav.Link href="/contact">Контакт</Nav.Link>
                    </Nav>
                    <div className="d-flex">
                        {logedIn
                        ?<div className='d-flex flex-row align-items-center'>
                            <Nav.Link href="/profile" className='d-flex align-items-center'><BsPersonCircle className="nav-profile-icon"/>{pb?.authStore.model.name}</Nav.Link>
                            <Button onClick={handleLogOut} className="primaryBtn logOutBtn">Излез</Button>
                        </div>
                        :<>
                            <LinkPrimaryBtn link="/login" text="Влез"/>
                            <LinkPrimaryBtn link="/register" text="Регистрација"/>
                        </>
                        }
                        
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>

        <Modal show={show} onHide={handleClose} className="addImageModal">
                    <Modal.Header closeButton>
                        <Modal.Title className="addImageModalTitle">ПРИКАЧИ СЛИКА</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><AddImageFrom/></Modal.Body>
                    {/* <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleClose}>
                            Save Changes
                        </Button>
                    </Modal.Footer> */}
                </Modal>
        <Outlet /></>}</>
    );
}

export default Layout;
