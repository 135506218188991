import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import '../../App.css';

const LinkPrimaryBtn = (details) => {
    return (
        <><a href={details.link} className='primaryLinkBtn'>{details.text}</a></>
    );
}

export default LinkPrimaryBtn;
